@import 'styles/theme.scss';

.circle-loading {
  border-radius: 30px;
  border: 2px solid $primary-color;
  border-top: 2px solid transparent;
  animation: rotate360 0.8s linear infinite;

  &.small {
    height: 15px;
    width: 15px;
  }

  &.medium {
    height: 25px;
    width: 25px;
  }

  &.large {
    height: 40px;
    width: 40px;
  }
}

@keyframes rotate360 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}